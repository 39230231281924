/* Modal Content Adjustments */
.form-container {
    padding: 16px; /* Reduced padding for a more compact layout */
    display: flex;
    flex-direction: column;
    gap: 24px; /* Reduced gap between elements */
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    max-height: 70vh; /* Limit the height to fit in the modal */
    overflow-y: auto; /* Allow scrolling if content overflows */
  }
  
  .form-title {
    font-family: 'Poppins', sans-serif;
    font-size: 24px; /* Slightly smaller font size */
    font-weight: bold;
    text-align: center;
  }
  .form-title > span {
    position: relative;
  }
  .form-title span::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #9D2553;
    border-radius: 8px;
    left: 0;
    bottom: -8px;
  }
  
  /* Form Rows */
  .form-row {
    display: flex;
    gap: 16px; /* Space between fields in a row */
    margin-bottom: 12px; /* Margin between rows */
  }
  
  .form-row label {
    flex: 1; /* Allow labels to take up equal space */
    margin: 0; /* Remove margin for labels in a row */
  }
  
  /* Form */
  .form-content label {
    margin: 0; /* Remove margin for labels */
    display: block;
    font-family: 'Rubik', sans-serif;
    font-size: 18px; /* Slightly smaller font size */
    letter-spacing: .7px;
  }
  .form-content label::before {
    content: "*";
    color: red;
    padding: 0 4px 0 0;
  }
  .form-content label :is(input, select) {
    width: 100%;
    margin: 6px 0; /* Reduced margin */
    padding: 0 8px; /* Reduced padding */
    display: block;
    height: 36px; /* Slightly smaller height */
    color: black;
    border: 1px solid transparent;
    border-radius: 6px;
    background-color: #c9def4;
    outline: transparent;
    font-family: 'Rubik', sans-serif;
    font-size: 16px; /* Slightly smaller font size */
    letter-spacing: .7px;
  }
  
  /* Success Message */
  .success-message {
    margin: 12px 0 0 0; /* Reduced margin */
    color: green;
    font-family: 'Rubik', sans-serif;
    font-size: 16px; /* Slightly smaller font size */
    letter-spacing: .7px;
    line-height: 1.7rem;
    text-align: center;
  }
  
  /* Error Message */
  .error-message {
    color: red;
    font-family: 'Rubik', sans-serif;
    font-size: 14px; /* Slightly smaller font size */
    letter-spacing: .7px;
    line-height: 1.7rem;
  }
  
  /* Responsive */
  @media screen and (max-width: 600px) {
    .form-container {
      padding: 12px; /* Reduced padding */
    }
    .form-title {
      font-size: 20px; /* Slightly smaller font size */
    }
    .form-content label {
      font-size: 16px; /* Slightly smaller font size */
    }
    .form-content label :is(input, select) {
      font-size: 14px; /* Slightly smaller font size */
    }
    .success-message {
      margin: 8px 0 0 0; /* Reduced margin */
      font-size: 14px; /* Slightly smaller font size */
    }
  }
  

  .text-appointment-btn-modal {
    margin-top: 10px;
    padding: 7px 10px;
    color: white;
    border: 1px solid transparent;
    border-radius: 5px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}